.ant-table-filter-dropdown-link.confirm {
  width: 90px;
  -webkit-appearance: button;
  background: #0066ff;
  color: white;
  border: 1px solid #0066ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  outline: 0;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  text-transform: none;
  overflow: visible;
  margin: 0px 8px 0px 0px;
  font-family: inherit;
  box-sizing: border-box;
}

.ant-table-filter-dropdown-link.clear {
  width: 90px;
  -webkit-appearance: button;
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  outline: 0;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  text-transform: none;
  overflow: visible;
  margin: 0px 0px 0px 8px;
  font-family: inherit;
  box-sizing: border-box;
}

.ant-table-filter-dropdown-link.confirm:hover {
  background: #e6f7ff;
  color: #0066ff;
  border: 1px solid #0066ff;
}

.ant-table-filter-dropdown-link.clear:hover {
  background: #fff;
  color: #40a9ff;
  border-color: #40a9ff;
}