.entregas .ant-card-body {
  min-height: 100px;

  overflow: auto;
  text-overflow: ellipsis;
  background-color: #ffffff;
  white-space: pre-wrap;
}
.ant-list-item-meta-title {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
